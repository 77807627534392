import { validateEmail, validateRef } from "../validations";
import { EventName, parseEvent, postEvent } from "./events";
import { VPayDropin as VPayDropinInterface, CommonOptions, VPayDropinHandler, ChannelsType } from "./types";

const ENVIRONMENTS: { [key: string]: string } = {
  live: "",
  sandbox: "",
  localhost: "",
};

const resolveOptions = (options: CommonOptions): CommonOptions => {
  if (!options.domain) {
    const domain = ENVIRONMENTS[options.environment];
    options = { domain, ...options };
    if (!domain) {
      console.error(`VPayDropin unrecognised environment ${options.environment} : domain not set`);
    }
  }

  if (options.key === undefined) {
    throw new Error("VPayDropin missing key");
  }

  if (options.amount === undefined) {
    throw new Error("VPayDropin missing amount");
  }

  if (Number(options.amount) < 1) {
    throw new Error("VPayDropin amount must be greater than 1");
  }

  if (options.email && !validateEmail(options.email)) {
    throw new Error("VPayDropin invalid email");
  }
  if (options.email === undefined) {
    throw new Error("VPayDropin missing email");
  }
  if (options.transactionref === undefined) {
    throw new Error("VPayDropin missing transactionref");
  }

  if (options.customer_service_channel === undefined) {
    throw new Error("VPayDropin missing customer_service_channel");
  }

  if (!validateRef(options.transactionref)) {
    throw new Error("VPayDropin invalid transactionref. Transactionref must be alphanumeric");
  }

  const txnTypes = ["flat", "percentage"];

  if (options.txn_charge_type && txnTypes.indexOf(options.txn_charge_type) === -1) {
    throw new Error("VPayDropin invalid txn_charge_type. Valid values are 'flat' or 'percentage'");
  }

  const validChannels: ChannelsType[] = ["card", "ussd", "bank"];
  if (options.preferredChannel && !validChannels.includes(options.preferredChannel)) {
    throw new Error("VPayDropin invalid preferred channel. Valid values are 'card', 'ussd', or 'bank'");
  }

  if (options.txn_charge && Number(options.txn_charge_type) < 0) {
    throw new Error("VPayDropin txn_charge must be greater than 0 ");
  }

  return options;
};

const createIframe = (elementId: string, url: string) => {
  const iframe = document.createElement("iframe");

  iframe.setAttribute("src", url);
  iframe.setAttribute("id", elementId);
  iframe.setAttribute("name", elementId);
  iframe.setAttribute("height", "100%");
  iframe.setAttribute("width", "100%");
  // iframe.setAttribute("allowtransparency", "true");
  iframe.setAttribute("frame-src", "self");
  iframe.setAttribute(
    "style",
    `
          position: fixed;
          inset: 0px;
          z-index: 2147483647;
          border-width: 0px;
          display: flex;
          overflow: hidden auto;
          max-width: 100%;
          border: 0;
          background: rgba(0, 0, 0, 0.75);
          `
  );

  return iframe;
};

export const VPayDropin: VPayDropinInterface = {
  create(options: CommonOptions): VPayDropinHandler {
    // resolve options
    options = resolveOptions(options);

    // console.log(Function(options.onExit as any));

    const iframeID = `vpay-dropin-iframe-${Math.random().toString(7)}`;

    // const expectedOrigin = new URL(options.domain!).origin;

    const params: { mode: string } = {
      mode: "dropin",
    };

    const removeAndCleanup = (handler: (e: MessageEvent) => void) => {
      document.getElementById(iframeID)?.remove();
      window.removeEventListener("message", handler);
    };

    const eventHandler = (e: MessageEvent) => {
      const event = parseEvent({
        event: e,
        expectedOrigin: "*",
      });
      if (!event) {
        return;
      }

      const { onExit, onSuccess, ...rest } = options;

      switch (event?.name) {
        case EventName.AUTH_REQUEST:
          // TODO: handle auth request
          postEvent({
            target: (document.getElementById(iframeID) as HTMLIFrameElement).contentWindow!,
            event: {
              name: EventName.AUTH_CONFIRM,
              payload: {
                origin: window.location.origin,
                options: rest,
              },
            },
          });
          return;
        case EventName.INIT:
          return;
        case EventName.RETURN:
          removeAndCleanup(eventHandler);
          if (onSuccess) {
            onSuccess(event?.payload);
          }
          return;
        case EventName.EXIT:
          removeAndCleanup(eventHandler);
          if (onExit) {
            onExit(event?.payload, {});
          }
          return;
        default:
          break;
      }
    };

    const open = (): void => {
      window.addEventListener("message", eventHandler);

      const query = new URLSearchParams(params).toString();

      document.body.appendChild(createIframe(iframeID, `https://dropin-sandbox.vpay.africa?${query}`));
      // document.body.appendChild(createIframe(iframeID, `http://localhost:3000?${query}`));
      // document.body.appendChild(createIframe(iframeID, `https://dropin.vpay.africa?${query}`));
      // document.body.appendChild(createIframe(iframeID, `https://dpmirror.vpay.africa?${query}`));
    };

    return {
      open,
      exit: () => {
        removeAndCleanup(eventHandler);
      },
    };
  },
};

declare global {
  interface DropinWindow extends Window {
    VPayDropin: typeof VPayDropin;
  }
}

declare var window: DropinWindow;

window.VPayDropin = VPayDropin;

export default VPayDropin;
